export const flattenData = (data) => {
  return data.map((order) => ({
    ...order,

    // Flatten work_logs array into a semicolon-separated string
    work_logs: order.work_logs
      ? order.work_logs
          .map((log) => `${log.log_type} at ${log.create_date}`)
          .join('; ')
      : '',

    // Convert pm_contract object into a flattened text response using N/A for missing values
    pm_contract: order.pm_contract
      ? `Response Hours: ${order.pm_contract.response_hours ?? 'N/A'}, ` +
        `Repair Hours: ${order.pm_contract.repair_hours ?? 'N/A'}, ` +
        `Converted Response: ${order.pm_contract.converted_response ?? 'N/A'}, ` +
        `Converted Repair: ${order.pm_contract.converted_repair ?? 'N/A'}`
      : 'N/A',
  }))
}
